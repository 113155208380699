@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ARCO";
  src: url(assets/fonts/ARCO.ttf);
}

@font-face {
  font-family: "ARCO OSX";
  src: url(assets/fonts/ARCO\ for\ OSX.otf);
}

@font-face {
  font-family: "Explorer";
  src: url(assets/fonts/Explorer\ Free.otf);
}


@font-face {
  font-family: "MohrRounded-SemiBold";
  src: url(assets/fonts/MohrRoundedAlt-SemiBold.ttf);
}

@font-face {
  font-family: "MohrRounded-Bold";
  src: url(assets/fonts/MohrRoundedAlt-Bold.ttf);
}

@font-face {
  font-family: "MohrRounded-Heavy";
  src: url(assets/fonts/MohrRoundedAlt-Black.ttf);
}

@font-face {
  font-family: "MohrRounded-Medium";
  src: url(assets/fonts/MohrRoundedAlt-Medium.ttf);
}

.font-morh-m {
  font-family: MohrRounded-Medium;
}

.font-morh-b {
  font-family: MohrRounded-Bold;
}

.font-morh-h {
  font-family: MohrRounded-Heavy;
}

.font-arco {
  font-family: ARCO;
}

.font-morh-sb {
  font-family: MohrRounded-SemiBold;
}

.font-explorer {
  font-family: Explorer;
}

body {
  background-color: #8dd6f9;
  /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(31%, #8dd6f9), color-stop(40%, #8dd6f9), color-stop(42%, #8dd6f9), color-stop(57%, #8dd6f9), color-stop(78%, #ffac9a), color-stop(84%, #ffac9a), to(#ffac9a)); */
  /* background-image: linear-gradient(180deg, #8dd6f9 31%, #8dd6f9 40%, #8dd6f9 42%, #8dd6f9 57%, #ffac9a 78%, #ffac9a 84%, #ffac9a); */
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(33%, #8dd6f9), color-stop(44%, #bbefff), color-stop(67%, #ffac9a), color-stop(95%, #ffac9a), to(#fee881)); */
  /* background-image: linear-gradient(180deg, #fff, #8dd6f9 33%, #bbefff 44%, #ffac9a 67%, #ffac9a 95%, #fee881); */
}

.gradient-background {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(33%, #8dd6f9), color-stop(44%, #bbefff), color-stop(67%, #ffac9a), color-stop(95%, #ffac9a), to(#fee881));
  background-image: linear-gradient(180deg, #fff, #8dd6f9 33%, #bbefff 44%, #ffac9a 67%, #ffac9a 95%, #fee881);
}

.title-stroke {
  text-shadow: rgb(2, 0, 75) 6px 0px 0px, rgb(2, 0, 75) 5.91686px 0.995377px 0px, rgb(2, 0, 75) 5.66974px 1.96317px 0px, rgb(2, 0, 75) 5.2655px 2.87655px 0px, rgb(2, 0, 75) 4.71532px 3.71022px 0px, rgb(2, 0, 75) 4.03447px 4.44106px 0px, rgb(2, 0, 75) 3.24181px 5.04883px 0px, rgb(2, 0, 75) 2.35931px 5.51667px 0px, rgb(2, 0, 75) 1.41143px 5.83163px 0px, rgb(2, 0, 75) 0.424423px 5.98497px 0px, rgb(2, 0, 75) -0.574341px 5.97245px 0px, rgb(2, 0, 75) -1.55719px 5.79441px 0px, rgb(2, 0, 75) -2.49688px 5.45578px 0px, rgb(2, 0, 75) -3.36738px 4.96596px 0px, rgb(2, 0, 75) -4.14455px 4.33852px 0px, rgb(2, 0, 75) -4.80686px 3.59083px 0px, rgb(2, 0, 75) -5.33596px 2.74364px 0px, rgb(2, 0, 75) -5.71718px 1.8204px 0px, rgb(2, 0, 75) -5.93996px 0.84672px 0px, rgb(2, 0, 75) -5.99811px -0.150428px 0px, rgb(2, 0, 75) -5.89004px -1.14341px 0px, rgb(2, 0, 75) -5.61874px -2.1047px 0px, rgb(2, 0, 75) -5.19172px -3.00766px 0px, rgb(2, 0, 75) -4.62082px -3.82727px 0px, rgb(2, 0, 75) -3.92186px -4.54081px 0px, rgb(2, 0, 75) -3.11421px -5.12852px 0px, rgb(2, 0, 75) -2.22026px -5.57409px 0px, rgb(2, 0, 75) -1.26477px -5.86518px 0px, rgb(2, 0, 75) -0.274238px -5.99373px 0px, rgb(2, 0, 75) 0.723898px -5.95617px 0px, rgb(2, 0, 75) 1.70197px -5.75355px 0px, rgb(2, 0, 75) 2.63288px -5.39147px 0px, rgb(2, 0, 75) 3.49082px -4.87998px 0px, rgb(2, 0, 75) 4.25202px -4.23324px 0px, rgb(2, 0, 75) 4.89538px -3.46919px 0px, rgb(2, 0, 75) 5.40307px -2.60899px 0px, rgb(2, 0, 75) 5.76102px -1.67649px 0px, rgb(2, 0, 75) 5.95932px -0.697531px 0px;
}

.toggle-faq-icon {
  transform: rotate(266deg);
}

.toggle-faq-icon.active {
  transform: rotate(90deg);
}

.btn-shadow {
  --tw-shadow: 0px 4px 0px #02004B;
  --tw-shadow-colored: 0px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}